import * as React from 'react';
import DataContext from './DataContext';

class DataProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      setStatsSummary: this.setStatsSummary.bind(this),
      statsSummary: { lastSnapshot: { data: {} }, snapshots: [] },

      setAuctions: this.setAuctions.bind(this),
      auctions: { items: [] },

      setListedCards: this.setListedCards.bind(this),
      listedCards: { items: [] },

      setRewards: this.setRewards.bind(this),
      rewards: { items: [] },

      setManagerCards: this.setManagerCards.bind(this),
      managerCards: { items: [] },

      setCardsSortOrderSelectValue: this.setCardsSortOrderSelectValue.bind(this),
      cardsSortOrderSelectValue: 'recentDesc',

      setScoresTypeSelectValue: this.setScoresTypeSelectValue.bind(this),
      scoresTypeSelectValue: 'total',

      setScoresSortSelectValue: this.setScoresSortSelectValue.bind(this),
      scoresSortSelectValue: 'last15',

      setNbaScoresSortSelectValue: this.setNbaScoresSortSelectValue.bind(this),
      nbaScoresSortSelectValue: 'gameWeek',

      setNbaLineupScoresSortSelectValue: this.setNbaLineupScoresSortSelectValue.bind(this),
      nbaLineupScoresSortSelectValue: 'diff',

      setRaritySelectValue: this.setRaritySelectValue.bind(this),
      raritySelectValue: ['all'],

      setRarityPricesSelectValue: this.setRarityPricesSelectValue.bind(this),
      rarityPricesSelectValue: 'rare',

      setPositionSelectValue: this.setPositionSelectValue.bind(this),
      positionSelectValue: 'all',

      setClubSelectOptions: this.setClubSelectOptions.bind(this),
      clubSelectOptions: { items: [] },

      setClubSelectValue: this.setClubSelectValue.bind(this),
      clubSelectValue: -1,

      setLeagueSelectOptions: this.setLeagueSelectOptions.bind(this),
      leagueSelectOptions: { items: [] },

      setLeagueSelectValue: this.setLeagueSelectValue.bind(this),
      leagueSelectValue: -1,

      setU23SwitchValue: this.setU23SwitchValue.bind(this),
      u23SwitchValue: false,

      setFirstNumberSwitchValue: this.setFirstNumberSwitchValue.bind(this),
      firstNumberSwitchValue: false,

      setShirtNumberSwitchValue: this.setShirtNumberSwitchValue.bind(this),
      shirtNumberSwitchValue: false,

      setSpecialDesignSwitchValue: this.setSpecialDesignSwitchValue.bind(this),
      specialDesignSwitchValue: false,

      setLegendsSwitchValue: this.setLegendsSwitchValue.bind(this),
      legendsSwitchValue: false,

      setRewardSwitchValue: this.setRewardSwitchValue.bind(this),
      rewardSwitchValue: false,

      setInSeasonSwitchValue: this.setInSeasonSwitchValue.bind(this),
      inSeasonSwitchValue: false,

      setPlayedFilter: this.setPlayedFilter.bind(this),
      playedFilter: {
        l15: 0,
        l30: 0,
        all: 0,
      },

      setLow5thScoreSwitchValue: this.setLow5thScoreSwitchValue.bind(this),
      low5thScoreSwitchValue: false,

      setPlayedLastMatchSwitchValue: this.setPlayedLastMatchSwitchValue.bind(this),
      playedLastMatchSwitchValue: false,

      setAvailableOnMarketSwitchValue: this.setAvailableOnMarketSwitchValue.bind(this),
      availableOnMarketSwitchValue: false,

      setPricesFilter: this.setPricesFilter.bind(this),
      pricesFilter: {
        lastAuction: { min: 0.0, max: 2.0 },
        cheapestMarket: { min: 0.0, max: 2.0 },
      },

      setScoresFilter: this.setScoresFilter.bind(this),
      scoresFilter: {
        l15: { min: 0, max: 100 },
        l30: { min: 0, max: 100 },
        all: { min: 0, max: 100 },
      },

      setPlayerData: this.setPlayerData.bind(this),
      playerData: {
        player: {}, prices: {}, scores: {},
      },

      setTopPlayersData: this.setTopPlayersData.bind(this),
      topPlayersData: { items: [] },

      setTopNbaPlayersData: this.setTopNbaPlayersData.bind(this),
      topNbaPlayersData: { items: [] },

      resetFilters: this.resetFilters.bind(this),
    };
  }

  setStatsSummary(statsSummary) {
    this.setState({ statsSummary });
  }

  setAuctions(auctions) {
    this.setState({ auctions });
  }

  setListedCards(listedCards) {
    this.setState({ listedCards });
  }

  setRewards(rewards) {
    this.setState({ rewards });
  }

  setManagerCards(managerCards) {
    this.setState({ managerCards });
  }

  setCardsSortOrderSelectValue(cardsSortOrderSelectValue) {
    this.setState({ cardsSortOrderSelectValue });
  }

  setScoresTypeSelectValue(scoresTypeSelectValue) {
    this.setState({ scoresTypeSelectValue });
  }

  setScoresSortSelectValue(scoresSortSelectValue) {
    this.setState({ scoresSortSelectValue });
  }

  setNbaScoresSortSelectValue(nbaScoresSortSelectValue) {
    this.setState({ nbaScoresSortSelectValue });
  }

  setNbaLineupScoresSortSelectValue(nbaLineupScoresSortSelectValue) {
    this.setState({ nbaLineupScoresSortSelectValue });
  }

  setRaritySelectValue(raritySelectValue) {
    let processedRaritySelectValue = raritySelectValue;
    if (raritySelectValue.length === 0 || raritySelectValue.lastIndexOf('all') === raritySelectValue.length - 1) {
      processedRaritySelectValue = ['all'];
    } else if (raritySelectValue.length > 1 && raritySelectValue.includes('all')) {
      processedRaritySelectValue = raritySelectValue.filter((rarity) => rarity !== 'all');
    }

    this.setState({ raritySelectValue: processedRaritySelectValue });
  }

  setRarityPricesSelectValue(rarityPricesSelectValue) {
    this.setState({ rarityPricesSelectValue });
  }

  setPositionSelectValue(positionSelectValue) {
    this.setState({ positionSelectValue });
  }

  setClubSelectOptions(clubSelectOptions) {
    this.setState({ clubSelectOptions });
  }

  setClubSelectValue(clubSelectValue) {
    this.setState({ clubSelectValue });
  }

  setLeagueSelectOptions(leagueSelectOptions) {
    this.setState({ leagueSelectOptions });
  }

  setLeagueSelectValue(leagueSelectValue) {
    this.setState({ leagueSelectValue });
  }

  setU23SwitchValue(u23SwitchValue) {
    this.setState({ u23SwitchValue });
  }

  setFirstNumberSwitchValue(firstNumberSwitchValue) {
    this.setState({ firstNumberSwitchValue });
  }

  setShirtNumberSwitchValue(shirtNumberSwitchValue) {
    this.setState({ shirtNumberSwitchValue });
  }

  setSpecialDesignSwitchValue(specialDesignSwitchValue) {
    this.setState({ specialDesignSwitchValue });
  }

  setLegendsSwitchValue(legendsSwitchValue) {
    this.setState({ legendsSwitchValue });
  }

  setRewardSwitchValue(rewardSwitchValue) {
    this.setState({ rewardSwitchValue });
  }

  setInSeasonSwitchValue(inSeasonSwitchValue) {
    this.setState({ inSeasonSwitchValue });
  }

  setPlayedFilter(playedFilter) {
    this.setState({ playedFilter });
  }

  setLow5thScoreSwitchValue(low5thScoreSwitchValue) {
    this.setState({ low5thScoreSwitchValue });
  }

  setPlayedLastMatchSwitchValue(playedLastMatchSwitchValue) {
    this.setState({ playedLastMatchSwitchValue });
  }

  setAvailableOnMarketSwitchValue(availableOnMarketSwitchValue) {
    this.setState({ availableOnMarketSwitchValue });
  }

  setPricesFilter(pricesFilter) {
    this.setState({ pricesFilter });
  }

  setScoresFilter(scoresFilter) {
    this.setState({ scoresFilter });
  }

  setPlayerData(playerData) {
    this.setState({ playerData });
  }

  setTopPlayersData(topPlayersData) {
    this.setState({ topPlayersData });
  }

  setTopNbaPlayersData(topNbaPlayersData) {
    this.setState({ topNbaPlayersData });
  }

  resetFilters() {
    this.setState({
      cardsSortOrderSelectValue: 'recentDesc',
      scoresTypeSelectValue: 'total',
      scoresSortSelectValue: 'last15',
      nbaScoresSortSelectValue: 'gameWeek',
      nbaLineupScoresSortSelectValue: 'diff',
      raritySelectValue: ['all'],
      rarityPricesSelectValue: 'rare',
      positionSelectValue: 'all',
      clubSelectValue: -1,
      leagueSelectValue: -1,
      u23SwitchValue: false,
      firstNumberSwitchValue: false,
      shirtNumberSwitchValue: false,
      specialDesignSwitchValue: false,
      legendsSwitchValue: false,
      rewardSwitchValue: false,
      inSeasonSwitchValue: false,
      playedFilter: {
        l15: 0,
        l30: 0,
        all: 0,
      },
      low5thScoreSwitchValue: false,
      playedLastMatchSwitchValue: false,
      availableOnMarketSwitchValue: false,
      pricesFilter: {
        lastAuction: { min: 0.0, max: 2.0 },
        cheapestMarket: { min: 0.0, max: 2.0 },
      },
      scoresFilter: {
        l15: { min: 0, max: 100 },
        l30: { min: 0, max: 100 },
        all: { min: 0, max: 100 },
      },
    });
  }

  render() {
    const { children } = this.props;
    return (
      <DataContext.Provider value={{
        dataContext: {
          ...this.state,
        },
      }}
      >
        {children}
      </DataContext.Provider>
    );
  }
}

export default DataProvider;
